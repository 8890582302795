import { useContext, useState, useEffect } from "react";
import { useForm, Controller } from 'react-hook-form';
import { DeSoIdentityContext } from "react-deso-protocol";
import { getDisplayName, getFullDisplayName } from "../helpers";
import { identity, updateProfile, updateFollowingStatus, getHodlersForUser } from "deso-protocol";
import userPrefsStore from 'context/userPrefsStore';
import { Loader2, X } from 'lucide-react';
import { Card, CardContent, CardHeader, CardTitle } from "components/ui/card";
import { Button } from "components/ui/button"
import { Link } from "components/ui/button"
import { Skeleton } from "components/ui/skeleton"
import { Label } from "components/ui/label"
import { Input } from "components/ui/input"
import { Switch } from "components/ui/switch"
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import BigNumber from 'bignumber.js';
import Turnstile, { useTurnstile } from "react-turnstile";
import party from "party-js";
import React, { useRef } from 'react';
import {
  Avatar,
  AvatarImage,
  AvatarFallback
} from "components/ui/avatar";
import desocialWorldLogo from "../assets/desocialworld-bw.svg";
import diamondLogo from "../assets/diamond_logo_bw.svg";
import nftzLogo from "../assets/nftz-logo-bw.svg";
import desoStep1 from "../assets/step1.png";
import desoStep2 from "../assets/step2.jpg";
import desoStep3 from "../assets/step3.png";
import LatestNFT from 'components/latestNft';
import { AlertDialog, AlertDialogTrigger, AlertDialogContent, AlertDialogHeader, AlertDialogTitle, AlertDialogDescription, AlertDialogFooter, AlertDialogCancel, AlertDialogAction } from "components/ui/alert-dialog";

export const Home = () => {
  const ICON_SIZE = 48;
  const { currentUser, isLoading } = useContext(DeSoIdentityContext);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [resetTwitterAPI, setResetTwitterAPI] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [message, setMessage] = useState("");
  const [isFormVisible, setFormVisible] = useState(true);
  const [isSyncEnabled, setIsSyncEnabled] = useState(true);  // Default to true (enabled)
  const [hasHashtagError, setHasHashtagError] = useState(false);
  const { userPrefs, setUserPrefs } = useContext(userPrefsStore);
  const [hasClaimableNFT, setHasClaimableNFT] = useState(false);
  const [allNFTsClaimed, setAllNFTsClaimed] = useState(false);
  const [userHasClaimed, setUserHasClaimed] = useState(false);
  const [apiError, setApiError] = useState(null);
  const [imageURLs, setImageURLs] = useState([]);
  const [edition, setEdition] = useState("");
  const [series, setSeries] = useState("");
  const [apiResponse, setApiResponse] = useState(null);
  const [isSubmissionSuccessful, setIsSubmissionSuccessful] = useState(false);
  const [postHashHex, setPostHashHex] = useState(null);
  const cardRef = useRef(null); 
  const [userHasNoProfile, setUserHasNoProfile] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isNewUserJustClaimed, setIsNewUserJustClaimed] = useState(false);
  const [username, setUsername] = useState('');
  const [uploadedImage, setUploadedImage] = useState(null);
  const [availabilityMessage, setAvailabilityMessage] = useState('');
  const [canSubmit, setCanSubmit] = useState(true);
  const debounceRef = useRef(null);
  const [uploadedImageFile, setUploadedImageFile] = useState(null);
  const [modalErrorMessage, setModalErrorMessage] = useState(null);
  const [isNewUser, setIsNewUser] = useState(false);
  const [showNewUserModal, setShowNewUserModal] = useState(false);
  const [showOnboardNewUserModal, setShowOnboardNewUserModal] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [isNewUserNowExplore, setIsNewUserNowExplore] = useState(false);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const ownedCopiesCountRef = useRef(0);
  const numNFTCopiesRef = useRef(0);
  const numNFTCopiesClaimedRef = useRef(0);
  const [turnstileToken, setTurnstileToken] = useState(null);
  const [tokensHeld, setTokensHeld] = useState(0);
  const [tokensHeldNumber, setTokensHeldNumber] = useState(0);
  const [isDialogOpen, setIsDialogOpen] = useState(false); // For dialog visibility
  const [alertMessage, setAlertMessage] = useState(""); // For alert dialog message
  const [latestNFTKey, setLatestNFTKey] = useState(0); 
  const [alertTitle, setAlertTitle] = useState("");
  const [alertLoadingMessage, setAlertLoadingMessage] = useState("");

  
  //shadcn
  const handleBidClick = async (postHashHex, bidAmount) => {
    console.log("Clicked Bid");
    setIsDialogOpen(true); // Open the dialog
    setIsSubmitting(true); // Show the loader
    setAlertMessage("Processing your bid...");
    setAlertLoadingMessage("Processing your bid...");
    setAlertTitle("Submitting Bid");
    let nftLimitMap = {};
        nftLimitMap[postHashHex] = {};
        nftLimitMap[postHashHex][0] = {
            any: 1,
        };
    try {
          console.log("Getting permission")
          try {
            if (
              !identity.hasPermissions({
                GlobalDESOLimit: 10000000, // 0.01 DESO 
                    TransactionCountLimitMap: {
                        BASIC_TRANSFER: 2,
                        SUBMIT_POST: 2,
                        FOLLOW: 50,
                        LIKE: 1,
                    },
                    DAOCoinOperationLimitMap: {
                      [process.env.REACT_APP_TOKEN_PUBLIC_KEY]: {
                        transfer: "UNLIMITED",
                      }
                    },
                    "NFTOperationLimitMap": nftLimitMap,
                })
            ) {
              await identity.requestPermissions({
                GlobalDESOLimit: 10000000, // 0.01 DESO 
                    TransactionCountLimitMap: {
                        BASIC_TRANSFER: 2,
                        SUBMIT_POST: 2,
                        FOLLOW: 50,
                        LIKE: 1,
                    },
                    DAOCoinOperationLimitMap: {
                      [process.env.REACT_APP_TOKEN_PUBLIC_KEY]: {
                        transfer: "UNLIMITED",
                      }
                    },
                    "NFTOperationLimitMap": nftLimitMap,
                })
            }
          } catch (error) {
            console.error("Error requesting permissions:", error);
            setApiResponse('Permission request failed.');
            setIsSubmitting(false);
            return;
          }
           
  
    } catch (error) {
        handleIdentityError(error);
        setApiResponse('There was an error.');
        setIsSubmitting(false);
        return;
    }
    try {
        if (!executeRecaptcha) {
            console.error('Execute recaptcha not yet available');
            setApiResponse('Are you a bot?');
            setIsSubmitting(false);
            return;
        }
  
        let token;
        try {
            token = await executeRecaptcha('submit'); // The action is 'submit' here.
        } catch (error) {
            console.error('Error while executing reCAPTCHA:', error);
            setApiResponse('Are you a bot?');
            setIsSubmitting(false);
            return;
        }
  
        if (!token) {
            console.error('reCAPTCHA validation failed.');
            setApiResponse('Are you a bot?');
            setIsSubmitting(false);
            return;
        }
        setApiResponse(null);
        setIsSubmitting(true);
        console.log("Submitting")
  
        console.log("awating derived data")
  
        const derivedData = await getDerivedData();
        let derived_key = derivedData.derivedPublicKey;
        let derived_seed = derivedData.derivedSeedHex;
        let expirationBlock = derivedData.expirationBlock;
        let accessSignature = derivedData.accessSignature;
        let transactionSpendingLimitHex = derivedData.transactionSpendingLimitHex;
  
        const payload = {
            public_key: currentUser.PublicKeyBase58Check,
            username: getFullDisplayName(currentUser),
            post_hash_hex: postHashHex,
            derived_public_key: derived_key,
            derived_seed_hex: derived_seed,
            expirationBlock: expirationBlock,
            accessSignature: accessSignature,
            transactionSpendingLimitHex: transactionSpendingLimitHex,
            bid_amount: bidAmount,
            captcha_site_key: token,
            captcha: "",
            cloudflare_turnstine_token: turnstileToken
        };
        console.log("Bidding")
  
        fetch('https://fwrn6lmgal.execute-api.us-west-2.amazonaws.com/production/entry', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
        })
            .then(response => response.json())
            .then(data => {
                setIsSubmitting(false); // Stop showing the loader
                const responseBody = JSON.parse(data.body);  // Parse the stringified JSON body
                setApiResponse(responseBody.message);
                setIsSubmitting(false);
                if (data.statusCode === 200) {
                    setIsSubmissionSuccessful(true);
                    setAlertMessage("Bid successful!");
                    party.confetti(cardRef.current);
                } else if (data.statusCode != 200) {
                    console.log("404 returned but considering submission successful");
                    setAlertMessage(responseBody.error);
                    setIsSubmissionSuccessful(true);
                }
            })
            .catch(error => {
                console.error('Error during claim:', error);
                setApiResponse('Sorry, there was an error. Please try again later.');
                setIsSubmitting(false);
                setAlertMessage("An error occurred. Please try again later.");
            });
  
    } catch (error) {
        console.error('Unexpected error in handleClaimClick:', error);
        setApiResponse('An unknown error occurred. Please try again later.');
        setIsSubmitting(false);
    }
  };
  
    function handleIdentityError(error) {
      if (error.message && error.message.includes('RuleErrorInsufficientBalance')) {
          alert("Sorry, you don't have enough DeSo to claim this NFT!");
      } else {
          const errorMessage = error.message ? error.message.split(':').pop().trim() : "An unknown error occurred";
          alert(errorMessage);
      }
  }

  const handleCancelBidClick = async (postHashHex) => {
    console.log("Clicked Cancel Bid");
    setIsDialogOpen(true); // Open the dialog
    setIsSubmitting(true); // Show the loader
    setAlertMessage("Cancelling your bid...");
    setAlertLoadingMessage("Cancelling your bid...");
    setAlertTitle("Cancelling Bid");
    let nftLimitMap = {};
        nftLimitMap[postHashHex] = {};
        nftLimitMap[postHashHex][0] = {
            any: 1,
        };
    try {
          console.log("Getting permission")
            try {
                await identity.requestPermissions({
                    GlobalDESOLimit: 10000000, // 0.01 DESO
                    TransactionCountLimitMap: {
                        BASIC_TRANSFER: 2,
                        SUBMIT_POST: 2,
                        FOLLOW: 50,
                        LIKE: 1,
                    },
                    DAOCoinOperationLimitMap: {
                      [process.env.REACT_APP_TOKEN_PUBLIC_KEY]: {
                        transfer: "UNLIMITED",
                      }
                    },
                    "NFTOperationLimitMap": nftLimitMap,
                });
            } catch (permissionError) {
                console.error('Error during permission request:', permissionError);
                setApiResponse('Permission request failed.');
                setIsSubmitting(false);
                return;
            }
  
    } catch (error) {
        handleIdentityError(error);
        setApiResponse('There was an error.');
        setIsSubmitting(false);
        return;
    }
    try {
        if (!executeRecaptcha) {
            console.error('Execute recaptcha not yet available');
            setApiResponse('Are you a bot?');
            setIsSubmitting(false);
            return;
        }
  
        let token;
        try {
            token = await executeRecaptcha('submit'); // The action is 'submit' here.
        } catch (error) {
            console.error('Error while executing reCAPTCHA:', error);
            setApiResponse('Are you a bot?');
            setIsSubmitting(false);
            return;
        }
  
        if (!token) {
            console.error('reCAPTCHA validation failed.');
            setApiResponse('Are you a bot?');
            setIsSubmitting(false);
            return;
        }
        setApiResponse(null);
        setIsSubmitting(true);
        console.log("Submitting")
  
        console.log("awating derived data")
  
        const derivedData = await getDerivedData();
        let derived_key = derivedData.derivedPublicKey;
        let derived_seed = derivedData.derivedSeedHex;
        let expirationBlock = derivedData.expirationBlock;
        let accessSignature = derivedData.accessSignature;
        let transactionSpendingLimitHex = derivedData.transactionSpendingLimitHex;
  
        const payload = {
            public_key: currentUser.PublicKeyBase58Check,
            username: getFullDisplayName(currentUser),
            post_hash_hex: postHashHex,
            derived_public_key: derived_key,
            derived_seed_hex: derived_seed,
            expirationBlock: expirationBlock,
            accessSignature: accessSignature,
            transactionSpendingLimitHex: transactionSpendingLimitHex,
            captcha_site_key: token,
            captcha: "",
            cloudflare_turnstine_token: turnstileToken
        };
        console.log("Cancelling Bid")
  
        fetch('https://fwrn6lmgal.execute-api.us-west-2.amazonaws.com/production/cancel', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
        })
            .then(response => response.json())
            .then(data => {
                setIsSubmitting(false); // Stop showing the loader
                const responseBody = JSON.parse(data.body);  // Parse the stringified JSON body
                setApiResponse(responseBody.message);
                setIsSubmitting(false);
                if (data.statusCode === 200) {
                    setIsSubmissionSuccessful(true);
                    setAlertMessage("Canceled your bid");
                    party.confetti(cardRef.current);
                } else if (data.statusCode === 404) {
                    console.log("404 returned but considering submission successful");
                    setAlertMessage("Failed to cancel bid. Please try again.");
                    setIsSubmissionSuccessful(true);
                }
            })
            .catch(error => {
                console.error('Error during claim:', error);
                setApiResponse('Sorry, there was an error. Please try again later.');
                setIsSubmitting(false);
                setAlertMessage("An error occurred. Please try again later.");
            });
  
    } catch (error) {
        console.error('Unexpected error in handleCancelClaimClick:', error);
        setApiResponse('An unknown error occurred. Please try again later.');
        setIsSubmitting(false);
    }
  };

  const getDerivedData = async () => {
    if (currentUser && currentUser.PublicKeyBase58Check) {
      const desoIdentityUsers = localStorage.getItem("desoIdentityUsers");
      const parsedData = JSON.parse(desoIdentityUsers);
      const targetUserKey = currentUser.PublicKeyBase58Check;

      let derivedDeSo;
      let retryCount = 0;

      while(retryCount < 3){
          derivedDeSo = parsedData[targetUserKey]?.primaryDerivedKey?.transactionSpendingLimits?.GlobalDESOLimit;

          if(derivedDeSo !== undefined){
              break;
          }

          await new Promise(r => setTimeout(r, 1000));
          retryCount++;
      }

      return {
        derivedPublicKey: parsedData[targetUserKey]?.primaryDerivedKey?.derivedPublicKeyBase58Check,
        derivedSeedHex: parsedData[targetUserKey]?.primaryDerivedKey?.derivedSeedHex,
        derivedDeSo: derivedDeSo,
        expirationBlock: parsedData[targetUserKey]?.primaryDerivedKey?.expirationBlock,
        accessSignature: parsedData[targetUserKey]?.primaryDerivedKey?.accessSignature,
        transactionSpendingLimitHex: parsedData[targetUserKey]?.primaryDerivedKey?.transactionSpendingLimitHex
      };
    }
    return { derivedPublicKey: null, derivedSeedHex: null, derivedDeSo: null, expirationBlock: null, accessSignature: null, transactionSpendingLimitHex: null };
  }

  function formatNumberCompact(number) {
    if (number < 1000) {
      return number; // Return the number as-is if less than 1000
    } else if (number < 1000000) {
      return (number / 1000).toFixed(1) + 'k'; // Convert to thousands with one decimal place
    } else if (number < 1000000000) {
      return (number / 1000000).toFixed(1) + 'M'; // Convert to millions with one decimal place
    } else {
      return (number / 1000000000).toFixed(1) + 'B'; // Convert to billions with one decimal place
    }
  }
  async function getTokenCount() {
    if (!currentUser) {
      return; // Exit the function if currentUser is null
    }
    let tokenHolders = await getHodlersForUser({
      Username: "",
      FetchAll: true,
      FetchHodlings: true,
      PublicKeyBase58Check: currentUser.PublicKeyBase58Check,
      IsDAOCoin: true
    });
  
    // Ensure tokenHolders is not undefined or null
    if (tokenHolders && tokenHolders.Hodlers) {
      const { Hodlers } = tokenHolders;
      
      // Find the hodler by public key
      const hodler = Hodlers.find(h => h.CreatorPublicKeyBase58Check === process.env.REACT_APP_TOKEN_PUBLIC_KEY);
      
      // Update the state based on the found hodler
      if (hodler) {
        // Directly use the updated state value
        const balanceNanos = new BigNumber(hodler.BalanceNanosUint256); // BalanceNanos from your JSON
        const tokens = balanceNanos.dividedBy(new BigNumber("1e18"));
        const formattedTokens = formatNumberCompact(Number(tokens.toFixed(0)));
        setTokensHeld(formattedTokens.toString()); // Ensure it's a string
        setTokensHeldNumber(tokens.toFixed(0).toString()); // Convert to string
      } else {
        setTokensHeld(0);
        setTokensHeldNumber(0)
      }
    } else {
      console.log("No token holders data found");
      setTokensHeld(0);
      setTokensHeldNumber(0)
    }
  }

  const handleOkClick = () => {
    setIsDialogOpen(false); // Close the dialog
    setLatestNFTKey(prevKey => prevKey + 1); // Update key to force re-render of LatestNFT
  };

  useEffect(() => {
    if (!isLoading && currentUser) {
      setTokensHeld(0);
      setTokensHeldNumber(0)
      getTokenCount();
    }
    // removed , currentUser
  }, [isLoading]);

  return (
    <div>
      {!isLoading && (
        <>
          <LatestNFT 
            key={latestNFTKey}  // Use key to force re-render
            handleBidClick={handleBidClick} 
            handleCancelBidClick={handleCancelBidClick}
            tokensHeld={tokensHeld} 
            tokensHeldNumber={tokensHeldNumber} 
          />

          {/* AlertDialog from shadcn */}
          
          <AlertDialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
            <AlertDialogContent>
              <AlertDialogHeader>
              <div ref={cardRef}></div>
                <AlertDialogTitle>{alertTitle}</AlertDialogTitle>
                <AlertDialogDescription>
                  {isSubmitting ? (
                    <div className="flex items-center">
                      <Loader2 className="mr-2 animate-spin" size={24} />
                      {alertLoadingMessage}
                    </div>
                  ) : (
                    alertMessage
                  )}
                </AlertDialogDescription>
              </AlertDialogHeader>
              <AlertDialogFooter>
                {!isSubmitting && (
                  <AlertDialogAction onClick={handleOkClick}>
                  OK
                </AlertDialogAction>
                )}
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
          
        </>
      )}
    </div>
  );
}
