import { DeSoIdentityProvider } from "react-deso-protocol";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./App.css";
import './tailwind.output.css';
import { Home } from "./routes/home";
import { Root } from "./routes/root";
import { Claim } from "./routes/claim";
import { SignAndSubmitTx } from "./routes/sign-and-submit-tx";
import { SwitchAccount } from "./routes/switch-account";
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { GetKeys } from "./routes/getkeys"
import { User } from "./routes/user";
import React, { useState } from "react";
import userPrefsStore from 'context/userPrefsStore';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    children: [
      { path: "/", element: <Home /> },
      {
        path: "/how-to-get-your-twitter-api-keys",
        element: <GetKeys />,
      },
      {
        path: "/bid4nft", // Add this route for /bid4nft
        element: <Home />, // Replace with your actual component
      },
      {
        path: "/claim/:postHashHex",  // Here's the new Claim route with the postHashHex parameter
        element: <Claim />,
      },
    ],
  },
]);

function App() {
  const [userPrefs, setUserPrefs] = useState(null);

  return (
    <React.StrictMode>
      <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY} useRecaptchaNet={true}>
        <DeSoIdentityProvider>
          <userPrefsStore.Provider value={{ userPrefs, setUserPrefs }}>
            <RouterProvider router={router}>
            </RouterProvider>
          </userPrefsStore.Provider>
        </DeSoIdentityProvider>
      </GoogleReCaptchaProvider>
    </React.StrictMode>
  );
};

ReactDOM.createRoot(document.getElementById("root")).render(<App />);