import { identity } from "deso-protocol";
import { useContext, useState, useEffect } from "react";
import { DeSoIdentityContext } from "react-deso-protocol";
import { Link } from "react-router-dom";
import { getDisplayName } from "../helpers";
import logo from "../assets/mydesospace.png";
import desoStep1 from "../assets/step1.png";
import { Loader2, X } from 'lucide-react';
import desoStep2 from "../assets/step2.jpg";
import desoStep3 from "../assets/step3.png";
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
} from "components/ui/navigation-menu";
import { Button } from "components/ui/button";
import { Separator } from "components/ui/separator"
import {
  Avatar,
  AvatarImage,
} from "components/ui/avatar";

export const Nav = () => {
  const { currentUser, alternateUsers, isLoading } = useContext(DeSoIdentityContext);
  const [userPrefs, setUserPrefs] = useState(null);
  const [isUserLoading, setIsUserLoading] = useState(true);
  const [avatarUrl, setAvatarUrl] = useState('');
  const [showOnboardNewUserModal, setShowOnboardNewUserModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [modalErrorMessage, setModalErrorMessage] = useState(null);

  const signUpTutorial = () => {
  setShowOnboardNewUserModal(true);
  
}
const nextPage = () => {
  setCurrentPage(prevPage => Math.min(prevPage + 1, 3));
};

const prevPage = () => {
  setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
};

  useEffect(() => {
    if (!isLoading && currentUser) {
      setIsUserLoading(false);
      const url = getProfilePictureUrl(currentUser);
      setAvatarUrl(url);
    } else if(isLoading && currentUser) {
      const url = getProfilePictureUrl(currentUser);
      setAvatarUrl(url);
    } else {
      setIsUserLoading(false);
    }
  }, [isLoading, currentUser]);

  useEffect(() => {
    if (!currentUser && alternateUsers.length > 0) {
      identity.setActiveUser(alternateUsers[0].PublicKeyBase58Check);
    }
  }, [isLoading, currentUser, alternateUsers]);

  const getProfilePictureUrl = (user) => {
    return `https://node.deso.org/api/v0/get-single-profile-picture/${user.PublicKeyBase58Check}?fallback=https://node.deso.org/assets/img/default_profile_pic.png&timestamp=${Date.now()}`;
};

  return (
    <div className={`text-white fade-in ${!isLoading && !isUserLoading && 'visible'}`}>
      {showOnboardNewUserModal && (
  <>
  <div className="fixed inset-0 bg-black opacity-70 z-2" onClick={() => setShowOnboardNewUserModal(false)}></div>
    <div className="fixed inset-0 flex items-center justify-center z-50 ">
      <div className="bg-black p-8 rounded-lg shadow-lg relative w-full max-w-2xl md:w-3/4 border">
        
        {/* Close Button */}
        <button className="absolute right-2 font-bold top-2 text-white" onClick={() => setShowOnboardNewUserModal(false)}>
        <X size={18} />
        </button>
          
          {currentPage === 1 && (
            <>
              <h2 className="text-2xl font-bold text-white mb-4">Signing up for a DeSo Account is easy!</h2>
              <p className="text-gray-300 mb-2">Step 1: When you click "Sign Up" a window will pop-up and you can choose to sign up with your Google account, MetaMask or you can "Sign up with DeSo Seed":</p>
              <img src={desoStep1} alt="Step 1" className="rounded border bg-white p-1 dark:border-neutral-700 dark:bg-neutral-800" />
            </>
          )}
          
          {currentPage === 2 && (
            <>
              <p className="text-gray-300 mb-2">Step 2: If you select to use a Seed Phrase be sure and copy your Seed Phrase in a safe place (you’ll need this to login - it’s like a password). Then on the next screen paste the Seed Phrase back into the text box.</p>
              <img src={desoStep2} alt="Step 2" className="rounded border bg-white p-1 dark:border-neutral-700 dark:bg-neutral-800" />
            </>
          )}

          {currentPage === 3 && (
            <>
              <p className="text-gray-300 mb-2">Step 3: Then complete the captcha to receive some free DeSo and approve the signing access prompt. Ready? Go ahead and click the "Create Account" button!</p>
              <img src={desoStep3} alt="Step 3" className="rounded border bg-white p-1 dark:border-neutral-700 dark:bg-neutral-800" />
            </>
          )}

            <div className="mt-4 flex justify-between items-center"> 
            
            {currentPage > 1 ? (
                <Button onClick={prevPage}>
                    Back
                </Button>
            ) : (
                <span></span>
            )}
            
            {currentPage < 3 ? (
                <Button onClick={nextPage}>
                    Next
                </Button>
            ) : (
              <Button onClick={() => {
                identity.login();
                setShowOnboardNewUserModal(false);
            }}>
                Create Account
            </Button>
            )}
            
            {modalErrorMessage && (
                <div className="w-full mt-2 text-red-500 text-center">{modalErrorMessage}</div>
            )}
            </div>
        </div>
      </div>

  </>
)}
  <div className="h-full flex flex-col md:flex-row justify-between items-center py-4 md:h-16 container">
  <a href="https://mydesospace.com/browse?feedTab=Following" className="pb-1 flex items-center mx-auto md:mx-0">
    <img src={logo} alt="Home Logo" className="App-logo" />
    <h2 className="text-white logotext text-2xl ml-4 scroll-m-20 font-extrabold tracking-tight">
    </h2>
</a>

      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <NavigationMenu className="md:mx-0 mx-auto">
          <NavigationMenuList>
            {currentUser ? (
              <>
                <NavigationMenuItem>
                  <NavigationMenuTrigger className="cursor-pointer">
                    <div className="group relative flex items-center cursor-pointer">
                      <Avatar>
                        <AvatarImage src={getProfilePictureUrl(currentUser)} alt={getDisplayName(currentUser)} />
                      </Avatar>
                      <span className="main-nav__username ml-3">
                        {getDisplayName(currentUser)}
                      </span>
                    </div>
                  </NavigationMenuTrigger>
                  <NavigationMenuContent>
                    <ul className="grid p-1 m-1 md:w-[100px] lg:w-[200px]">
                      {alternateUsers?.map((user) => (
                        <NavigationMenuItem key={user.PublicKeyBase58Check}>
                            <NavigationMenuLink className="cursor-pointer">
                              <div
                                className="group relative flex items-center cursor-pointer block select-none space-y-1 rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground"
                                onClick={() => identity.setActiveUser(user.PublicKeyBase58Check)}
                              >
                                <img
                                  src={getProfilePictureUrl(user)}
                                  alt="Alternate User Profile"
                                  className="w-8 h-8 rounded-full mr-2 inline-block"
                                />
                                <div className="text-sm font-medium leading-none">
                                  {getDisplayName(user)}
                                </div>
                              </div>
                            </NavigationMenuLink>
                          </NavigationMenuItem>
                      ))}
                      
                      {alternateUsers && alternateUsers.length > 0 && <Separator />}
                      
                      
                        <NavigationMenuItem className="cursor-pointer">
                          <NavigationMenuLink className="cursor-pointer" onClick={() => identity.login()}>
                            <div className="block select-none space-y-1 rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground">
                              Add another account
                            </div>
                          </NavigationMenuLink>
                        </NavigationMenuItem>
                        <NavigationMenuItem className="cursor-pointer">
                          <NavigationMenuLink onClick={() => identity.logout()}>
                            <div className="block select-none space-y-1 rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground">
                              Logout
                            </div>
                          </NavigationMenuLink>
                        </NavigationMenuItem>
                    </ul>
                  </NavigationMenuContent>
                </NavigationMenuItem>
              </>
            ) : (
              <>
              <NavigationMenuItem>
                <Button variant="outline" className="mr-2" onClick={() => identity.login()}>Login</Button>
              </NavigationMenuItem>
              <NavigationMenuItem>
                <Button onClick={signUpTutorial}>Create Account</Button>
            </NavigationMenuItem>
            </>
              
            )}
          </NavigationMenuList>
        </NavigationMenu>
      )}
    </div>
    </div>
    
    
  );
};