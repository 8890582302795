import { useContext, useState, useEffect } from "react";
import { request } from 'graphql-request';
import { Button } from "components/ui/button";
import { Input } from "components/ui/input";
import { Card, CardContent, CardHeader, CardTitle } from "components/ui/card";
import { Skeleton } from "components/ui/skeleton";
import { DeSoIdentityContext } from "react-deso-protocol";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { RefreshCw, XCircle } from "lucide-react"
import { identity } from "deso-protocol";

const GET_NFT_POSTS = `
query Query($publicKey: String!, $filter: PostFilter, $orderBy: [PostsOrderBy!], $first: Int) {
  accountByPublicKey(publicKey: $publicKey) {
    posts(filter: $filter, orderBy: $orderBy, first: $first) {
      nodes {
        isNft
        nfts {
          nodes {
            owner {
              publicKey
              username
            }
          }
        }
        postHash
        imageUrls
        extraData
      }
    }
  }
}
`;

function LatestNFT({ handleBidClick, handleCancelBidClick, tokensHeld, tokensHeldNumber }) {
  const { currentUser, isLoading } = useContext(DeSoIdentityContext);
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [imageLoadedStates, setImageLoadedStates] = useState({});
  const [bidErrorMessage, setBidErrorMessage] = useState("");
  const [bidAmount, setBidAmount] = useState("");
  const [auctionEnded, setAuctionEnded] = useState(false);
  const minuteSeconds = 60;
  const hourSeconds = 3600;
  const daySeconds = 86400;

  const timerProps = {
    isPlaying: true,
    size: 100,
    strokeWidth: 10,
    colors: "#ffffff",
    trailColor: "#1a1a1a"
  };

  const renderTime = (dimension, time) => {
    const dimensionText = time === 1 ? dimension.slice(0, -1) : dimension;
    return (
      <div className="time-wrapper">
        <div className="time">{time}</div>
        <div>{dimensionText}</div>
      </div>
    );
  };

  const getTimeSeconds = (time) => (minuteSeconds - time) | 0;
  const getTimeMinutes = (time) => ((time % hourSeconds) / minuteSeconds) | 0;
  const getTimeHours = (time) => ((time % daySeconds) / hourSeconds) | 0;
  const getTimeDays = (time) => (time / daySeconds) | 0;

  // Function to get remaining time in various units
  const getRemainingTime = (auctionEndTime) => {
    const now = Math.floor(Date.now() / 1000); // Current time in seconds
    const endTimeInSeconds = Math.floor(auctionEndTime.getTime() / 1000); // Convert auctionEndTime to seconds
    const remainingTimeInSeconds = endTimeInSeconds - now; // Remaining time in seconds
  
    return remainingTimeInSeconds
    }

  const handleBidChange = (e) => {
    const value = e.target.value;
    const isWholeNumber = /^[0-9]+$/.test(value);
    const bidValue = Number(value);

    if (!isWholeNumber) {
      setBidErrorMessage("Please enter a whole number.");
    } else if (bidValue <= 0) {
      setBidErrorMessage("You must bid more than 0.");
    } else if (bidValue > Number(tokensHeldNumber)) {
      setBidErrorMessage("You cannot place a bid larger than your current token holdings.");
    } else {
      setBidErrorMessage(""); // Clear error if the bid is valid
    }

    setBidAmount(value);
  };

  useEffect(() => {
    const fetchNFTPost = async () => {
      try {
        const response = await fetch('https://node.deso.org/api/v0/get-posts-for-public-key', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            PublicKeyBase58Check: process.env.REACT_APP_MINTER_PUBLIC_KEY,
            NumToFetch: 200,
          }),
        });

        const data = await response.json();
        const nftPost = data.Posts.find((post) => post.IsNFT === true);

        if (nftPost) {
          setPost(nftPost);
        } else {
          setPost(null);
        }

        setLoading(false);
      } catch (err) {
        console.error("Error fetching NFT post:", err);
        setError(err);
        setLoading(false);
      }
    };

    fetchNFTPost();
  }, []);

  useEffect(() => {
    if (post) {
      const auctionEndTime = new Date(Number(post.TimestampNanos) / 1e6 + 1 * 24 * 60 * 60 * 1000 - 5 * 60 * 1000);
      const remainingTime = (auctionEndTime.getTime() - new Date().getTime()) / 1000;
      console.log(remainingTime)

      if (remainingTime <= 0) {
        setAuctionEnded(true); // Mark auction as ended if time has passed
      }
    }
  }, [post]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;
  if (!post) return <p>No NFT post found.</p>;

  const auctionEndTime = new Date(Number(post.TimestampNanos) / 1e6 + 1 * 24 * 60 * 60 * 1000 - 5 * 60 * 1000); // Subtract 5 minutes

  const remainingTime = (auctionEndTime.getTime() - new Date().getTime()) / 1000;

  const days = Math.ceil(remainingTime / daySeconds);
  const daysDuration = days * daySeconds;

  if (isLoading) {
    return <p>Loading...</p>;
  }

  // Conditionally display the auction ended message
  if (auctionEnded) {
    return (
      <div className="text-center p-4">
        <h1 className="text-3xl font-bold">Sorry, this auction has ended.</h1>
        <p className="text-lg mt-2">There will be a new SelfieCats NFT up for auction soon!</p>
      </div>
    );
  }

  return (
    <div className="flex flex-col justify-center items-center mx-auto w-full max-w-6xl px-4 lg:px-0">
      <div className="flex md:flex-row justify-center items-start space-y-6 lg:space-y-0 lg:space-x-6 w-full sm:flex-col flex-col" key={post.PostHashHex}>
        
        {/* NFT Card */}
        <Card className="w-full">
          <CardHeader className="px-6 pb-1">
            <div className="w-full h-0 pt-[100%] relative overflow-hidden rounded">
              {post.ImageURLs && post.ImageURLs.length > 0 ? (
                <>
                  {!imageLoadedStates[post.PostHashHex] && (
                    <Skeleton className="absolute w-full h-full top-0 left-0 rounded" />
                  )}
                  <img
                    src={post.ImageURLs[0]}
                    alt="NFT Art"
                    className={`w-full h-full object-cover absolute top-0 left-0 rounded ${
                      imageLoadedStates[post.PostHashHex] ? '' : 'hidden'
                    }`}
                    onLoad={() =>
                      setImageLoadedStates((prevState) => ({
                        ...prevState,
                        [post.PostHashHex]: true,
                      }))
                    }
                  />
                </>
              ) : (
                <p>No Image Available</p>
              )}
            </div>
            <CardTitle>
              <div className="flex justify-between items-center">
                <span>{post.PostExtraData?.['Collection Name'] || process.env.REACT_APP_MINTER_USERNAME}</span>
                <span>{post.PostExtraData?.Edition || ''}</span>
              </div>
            </CardTitle>
          </CardHeader>
          <CardContent></CardContent>
        </Card>

        {/* Bidding Information Card */}
        <Card className="w-full">
          <CardHeader className="px-6 pb-1 relative">
            <CardTitle>Highest Bids</CardTitle>
            <button 
              onClick={() => window.location.reload()} 
              className="text-white hover:text-gray-400 absolute right-4 top-2"
            >
              <RefreshCw className="h-6 w-6 cursor-pointer" />
            </button>
          </CardHeader>
          <CardContent>
          {post.PostExtraData?.CurrentBids ? (
              JSON.parse(post.PostExtraData.CurrentBids)
                .sort((a, b) => b.bidAmount - a.bidAmount)
                .map((bid, index) => (
                  <div key={index} className="flex justify-between items-center m-2">
                    <p>@{bid.bidderUsername} {bid.bidAmount} MDS tokens</p>

                    {/* Show "Cancel Bid" if currentUser matches bidderUsername */}
                    {currentUser?.ProfileEntryResponse?.Username === bid.bidderUsername && (
                      <button
                        onClick={() => handleCancelBidClick(post.PostHashHex)}
                        className="text-red-500 flex items-center space-x-2 hover:underline"
                      >
                        <XCircle className="h-5 w-5" />
                        <span>Cancel Bid</span>
                      </button>
                    )}
                  </div>
                ))
            ) : (
              <p>No Bids Yet</p>
            )}

            {/* If user is not logged in, show "Login to Bid" button */}
            {!currentUser ? (
              <div className="flex justify-center mt-4">
                <Button size="lg" onClick={() => identity.login()}>
                  Login to Bid
                </Button>
              </div>
            ) : (
              <>
                {/* Show token balance if user is logged in */}
                <p className="mt-2 mb-6 text-center">Your $MyDeSoSpace token balance: {tokensHeldNumber}</p>

                {/* If tokensHeldNumber > 0, show the bid input */}
                {tokensHeldNumber > 0 ? (
                  <div className="flex items-center space-x-4 mt-8">
                    <Button
                      className="flex-shrink-0 w-auto px-4 py-2 lg:min-w-[15rem]"
                      size="lg"
                      onClick={() => handleBidClick(post.PostHashHex, bidAmount)}
                      disabled={bidAmount === '' || bidErrorMessage !== ''}
                    >
                      Place Bid
                    </Button>
                    <Input
                      id="bidAmount"
                      placeholder="Enter bid amount"
                      className={`flex-grow w-full p-2 text-lg ${bidErrorMessage ? 'border-red-500' : ''}`}
                      value={bidAmount}
                      onChange={handleBidChange}
                      type="number"
                      min="1"
                    />
                  </div>
                ) : (
                  // If no tokens, show the Buy Tokens button
                  <div className="flex justify-center mt-4">
                    <a
                      href="https://openfund.com/d/MyDeSoSpace"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Button size="lg">Buy MDS Tokens</Button>
                    </a>
                  </div>
                )}
              </>
            )}

            {bidErrorMessage && <p className="text-red-500 mt-2">{bidErrorMessage}</p>}

            {/* Countdown Timer */}
            <h2 className="text-2xl font-semibold leading-none tracking-tight mt-6">Bidding Ends In:</h2>
            <div className="grid grid-cols-2 sm:grid-cols-4 gap-4 justify-items-center items-center mt-4">
              <CountdownCircleTimer
                {...timerProps}
                duration={daysDuration}
                initialRemainingTime={remainingTime - daySeconds}
              >
                {({ elapsedTime }) => {
                  const daysLeft = getTimeDays(daysDuration - elapsedTime);
                  return <span className="text-sm sm:text-lg">{renderTime("days", daysLeft)}</span>;
                }}
              </CountdownCircleTimer>

              <CountdownCircleTimer
                {...timerProps}
                duration={daySeconds}
                initialRemainingTime={remainingTime % daySeconds}
                onComplete={(totalElapsedTime) => ({
                  shouldRepeat: remainingTime - totalElapsedTime > hourSeconds
                })}
              >
                {({ elapsedTime }) => <span className="text-sm sm:text-lg">{renderTime("hours", getTimeHours(daySeconds - elapsedTime))}</span>}
              </CountdownCircleTimer>

              <CountdownCircleTimer
                {...timerProps}
                duration={hourSeconds}
                initialRemainingTime={remainingTime % hourSeconds}
                onComplete={(totalElapsedTime) => ({
                  shouldRepeat: remainingTime - totalElapsedTime > minuteSeconds
                })}
              >
                {({ elapsedTime }) => <span className="text-sm sm:text-lg">{renderTime("minutes", getTimeMinutes(hourSeconds - elapsedTime))}</span>}
              </CountdownCircleTimer>

              <CountdownCircleTimer
                {...timerProps}
                duration={minuteSeconds}
                initialRemainingTime={remainingTime % minuteSeconds}
                onComplete={(totalElapsedTime) => ({
                  shouldRepeat: remainingTime - totalElapsedTime > 0
                })}
              >
                {({ elapsedTime }) => {
                  if (getRemainingTime(auctionEndTime) <= 0) { setAuctionEnded(true)}
                  return <span className="text-sm sm:text-lg">{renderTime("seconds", getTimeSeconds(elapsedTime))}</span>}
                }
              </CountdownCircleTimer>
            </div>
            {/* Conditionally render the Buy More MDS Tokens button after the countdown */}
          {tokensHeldNumber > 0 && (
            <div className="flex justify-center mt-8">
              <a
                href="https://openfund.com/trade/MyDeSoSpace"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button variant="secondary">Buy More MDS Tokens</Button>
              </a>
            </div>
          )}
          </CardContent>
        </Card>
      </div>
    </div>
  );
}

export default LatestNFT;
